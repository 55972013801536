.page-sidebar {
    margin-bottom: 1rem;
    button {
        margin-top: 0;
    }
    .accordion {
        .card, .draggable-card-container {
            transition: box-shadow 0.2s;
            
            &:not(.is-dragging) {
                border-width: 0px;
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                
                &:first-of-type() {
                    border-top-width: 0;
                }
            }

            &.is-dragging {
                border-radius: 0;
            }

            
            .card-header {
                color: map-get($theme-colors, "primary");
                cursor: pointer;


                background: $white;
                border-bottom: none;

                font-weight: bold;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

}

.page-sidebar__item {
    font-size: 14px;
    overflow: hidden;
    text-align: left;

    &.active.end {
        font-weight: bold;
    }
}

.sidebar-item {
    display: flex;

    &:first-of-type() {
        .bar.top {
            display: none;
        }
    }

    &:last-of-type() {
        .bar.bottom {
            display: none;
        }
    }
}

.sidebar-item-dot {
    $stroke-width: 2px;
    $circle-diam: 14px;

    $default-color: $gray-500;

    position: relative;
    width: $circle-diam;
    margin: 0 0.5rem;

    .bar {
        position: absolute;
        left: calc(50% - #{$stroke-width/2});

        width: $stroke-width;
        height: 50%;

        background: $default-color;

        &.top {
            top: 0px;
        }

        &.bottom {
            bottom: 0px;
        }
    }

    .circle {

        $pos: $circle-diam/2;
        height: $circle-diam;
        width: $circle-diam;
        position: absolute;
        top: calc(50% - #{$circle-diam/2});
        left: calc(50% - #{$circle-diam/2});

        border: $stroke-width solid $default-color;
        border-radius: $circle-diam/2;

        background: $white;
    }


    &.active {
        $active-colour: map-get($theme-colors, "secondary");

        &:not(.end) {
            .bar {
                background: $active-colour;
                border-width: $stroke-width * 2;
            }
        }

        &.end {

            .bar.top,
            .circle {
                background: $active-colour;

            }

        }

        .circle {
            border-color: $active-colour;
        }

    }
}