@media(min-width: 992px) {
    .user-menu__collapse-content {
        display: none;
    }
}

@media(max-width: 992px) {
    .user-menu__icon-button {
        display: none;
    }
}