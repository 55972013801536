.markdown-viewer__content {
    padding: 1rem;

    img {
        max-width: 100%;
    }

    table {
        @extend .table;
        @extend .table-striped;
    }


    &.empty {
        height: 200px;
        display: flex;

        h4 {
            margin: auto;
            color: $gray-500;
        }
    }

    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.33rem;
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1.125rem;
    }
    h6 {
        font-size: 1rem;
    }

    h1, h2, h3, h4, h5, h6 {
        color: map-get($map: $theme-colors, $key: "primary");
        font-weight: 600;
    }

    h1, h2 {
        padding-bottom: 0.25rem;
        border-bottom: 1px solid $gray-300;
        margin-bottom: 1rem;
    }

    h2#contents {
        border-bottom: none;
        margin-bottom: 0.5rem;
    }

    a {
        color: map-get($map: $theme-colors, $key: "md-link");
    }

    blockquote {
        @extend .text-muted;        
        border-left: 4px solid $gray-500;
        padding-left: 20px;
    }
}