.toast-override {
    .toast-override__body {
        font-family: 'Montserrat', sans-serif;
    }



    .Toastify__toast--default {
        color: map-get($map: $theme-colors, $key: 'primary');
    }

    .Toastify__toast--info {
        background: map-get($map: $theme-colors, $key: 'info');
        color: map-get($map: $theme-colors, $key: 'primary');
    }

    .Toastify__toast--warning {
        background: map-get($map: $theme-colors, $key: 'warning');
        color: darken(map-get($map: $theme-colors, $key: 'warning'), 42%);

        .Toastify__close-button {
            color: darken(map-get($map: $theme-colors, $key: 'warning'), 42%);
        }
    }

    .Toastify__toast--error {
        background: map-get($map: $theme-colors, $key: 'danger');
    }

    .Toastify__toast--success {
        background: map-get($map: $theme-colors, $key: 'success');
    }
}