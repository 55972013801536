.courseware-page__ca-btn-img {
    height: 16px;
}

.courseware-page__headerExtras {
    button {
        margin-left: 0.5em;
    }
    .dropleft {
        display: inline-block;
        height: 2em;
        .dropdown-toggle::before {
            display: none;
        }
    }
}
