.page-base {
    position: relative;
}

.page-base__header-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.page-base__bg {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 0;
    z-index: -1;
    background: map-get($theme-colors, "primary");
    box-shadow: 0 0 10px 10px $gray-400;
}

.page-base__title {
    color: $white;
}

.page-base__header-extras {
    margin: auto 0 auto auto;
}

.page-base__subtitle {
    color: $white;
}
