// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

@font-face {
    font-family: 'Krana Fat B';
    src: local('Krana Fat B'), local('Krana-Fat-B'), url('/static/fonts/Krana Fat B.woff') format('woff');
}

// Theming
@import './theme/BootstrapTheme'; //This needs to be above the main Bootstrap import
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/highlight.js/styles/github.css';
@import '../../../node_modules/react-toastify/dist/ReactToastify.css';
@import '../../../node_modules/simplebar/dist/simplebar.min.css';
@import '../../../node_modules/video.js/dist/video-js.css';
@import '../../../node_modules/@videojs/themes/dist/fantasy/index.css';
@import './theme/Fonts';
@import './theme/Card';
@import './theme/Toast';
@import './theme/VideoPlayer';

// Component styles
@import './components/App';
@import './components/PageBase';
@import './components/Header';
@import './components/MarkdownViewer';
@import './components/PageSidebar';
@import './components/Auth';
@import './components/PathwayDesigner';
@import './components/SocialPage';
@import './components/ModuleFeedback';
@import './components/CoursewarePage'