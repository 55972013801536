.module-feedback {
    .feedback-container {
        padding: 1em;
    }
    background: map-get($theme-colors, "primary");
    color: white;
}

.module-feedback__comment {
    margin-top: 1em;
}

button.cancel-feedback {
    float: right;
    margin-top: 0;
    border-radius: 0;
}

.module-feedback__rating-button {
    width: 100%;
    .svg-inline--fa {
        margin-right: 0.4em;
        color: map-get($theme-colors, "primary");
    }
}

.module-feedback__issue-checklist {
    margin-left: 2em;
}

.module-feedback__loaderModal {
    text-align: center;
    padding: 0;
    padding-top: 1em;
    p {
        margin-top: 0.5em;
    }
}

.module-feedback__submit {
    color: map-get($theme-colors, "primary");
    margin-top: 1em;
}