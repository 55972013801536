.social-page__secondary {
    color: #9E9E9E;
    font-weight: 600;
    font-family: Montserrat;
    font-style: normal;
    p {
        margin-bottom: 0;
    }
}

.social-page__item {
   margin-left: 1.5em;
   margin-right: 1.5em;
   margin-bottom: 0.5em;
   padding-bottom: 0.5em;
   border-bottom: 1px solid #E0E0E0;
   a {
        font-weight: 600
   }
}

.social-page__heading {
    color: map-get($map: $theme-colors, $key: "primary");
    font-weight: 600;
    padding-top: 0.7em;
    padding-left: 0.6em;
    padding-bottom: 0.7em;
}

.social-page__address-line {
    margin-bottom: 0;
}
