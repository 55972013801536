.courses-list-container {
    // height: 500px; //TODO: Make this dynamic to page height;

    overflow-y: auto;

    .simplebar-mask {
        z-index: auto;
    }

    .simplebar-content {
        height: 100%;
    }
}