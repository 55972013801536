.App {

}

html {
    scroll-behavior: smooth;
    padding-bottom: 10em;
}

.user-menu__icon {
    display: block;
    height: 32px;
    width: 32px;
    mask: url('/static/icon/user_circle_64x64.svg');
    background-color: map-get($map: $theme-colors, $key: 'primary');
    transition: all 100ms ease-in-out;
    cursor: pointer;
    &:hover {
        filter: brightness(200%);
    }
}